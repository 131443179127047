import { company } from './Details';

export const reviewsTitle =
{
  name: company.name,
  type: 'AggregateRating',
  ratingValue: '3.4',
  reviewRating: '5',
  bestRating: '5',
  worstRating: '1',
  ratingCount: '438',
}
