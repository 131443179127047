
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import { company } from '@components/config/company/Details';
import { DefaultSeo, NextSeo } from 'next-seo';
import { NEXT_SEO_DEFAULT } from '../next-seo.config';
import Home from '@pages/Home';
import { JsonLdOrg } from '@components/config/strucureData/OrganisationJsonLd';
import { JsonLdFaq } from '@components/config/strucureData/FaqJsonLd';
import { JsonLdReview } from '@components/config/strucureData/ReviewsJsonLd';

export default function Index() {
  return (
    <>
      <NextSeo canonical={`${company.url}`} />
      <DefaultSeo {...NEXT_SEO_DEFAULT} />
      <JsonLdReview />
      <JsonLdOrg />
      <JsonLdFaq />
      <div>
        <Home />
      </div>
    </>
  );
}
