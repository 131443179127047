import Head from 'next/head';
import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { reviewsTitle } from '../company/Reviews';
import { useApi } from 'use-hook-api';
import { ReviewsApi } from '@api/reviewsApi';

export const JsonLdReview = () => {
  const [getReviews, { data: reviewsList, loading: reviewsLoader, refetch }] =
    useApi({
      cache: 'reviews'
    });
  useEffect(() => {

    getReviews(ReviewsApi(), () => { })
  }, []);

  const {
    name,
    type,
    reviewRating,
    ratingValue,
    bestRating,
    worstRating,
    ratingCount,
  } = reviewsTitle;

  const reviewObjects = reviewsList?.map((review) => {
    return {
      '@type': 'Review',
      reviewBody: review.body,
      author: {
        '@type': review.type,
        name: review.name,
      },
      datePublished: review.date,
      ratingValue: review.rating,
      reviewUrl: review.reviewUrl,
      avatarUrl: review.avatarUrl,
    };
  });

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: name,
            aggregateRating: {
              '@type': type,
              ratingValue: ratingValue,
              reviewRating: reviewRating,
              bestRating: bestRating,
              worstRating: worstRating,
              ratingCount: ratingCount,
            },
            reviews: reviewObjects,
          }),
        }}
      />
    </Head>
  );
};
